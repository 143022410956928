// //imports the axios library to send HTTP requests to a server and handle responses.
// import axios from "axios";

// // creates a default base URL for all requests made with this axios instance
// const axiosBase = axios.create({
//   baseURL: "http://localhost:1220/api",
// });

// export default axiosBase;


import axios from "axios";

// Create an Axios instance with the baseURL set from the environment variable
const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL,  // using environment variable for base URL
});

export default axiosBase;
